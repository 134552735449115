import React from "react"
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import ServiceInternalsList from "../service-internals";

const AccordionItem = (
    {
        title,
        description,
        index,
        activeIndex,
        setActiveIndex,
        isVisible
    }
) => {

    const sanitizeOpt = sanitizeOptions();
    const keyDown = (ev) => {
        if (ev.keyCode === 13) {
            setActiveIndex(index);
        }
    };


    return (
        <div className={'accordion-item'}>
                    {(index < 1) && (
                        <div
                            className={'accordion-separator top'}
                            style={{
                                width: isVisible ? '100%' : '0',
                                transition: 'all 1s ease'
                            }}
                        />
                    )}
                    <div
                        className={'accordion-separator bottom'}
                        style={{
                            width: isVisible ? '100%' : '0',
                            transition: 'all 1s ease'
                        }}
                    />
                <h4 className={`accordion-title h4-title-bold-services ${(index === activeIndex) ? 'active' : ''}`}
                     onClick={() => activeIndex === index ? setActiveIndex(null) : setActiveIndex(index)}
                     onKeyDown={keyDown}
                     role={'button'}
                     tabIndex={index}
                >
                    {title}
                </h4>
            {(!!description) && (
                <div className={'accordion-description'}>
                    {!!description?.contentWsw && (
                        <div className={'content body-font-styles'}
                        dangerouslySetInnerHTML={{__html: sanitizeHtml(description.contentWsw, sanitizeOpt.contentData)}}/>
                    )}

                    {!!description?.internalList && (
                        <ServiceInternalsList
                            list={description.internalList}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default AccordionItem;