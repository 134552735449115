import React, {useEffect, useState} from "react";
import sanitizeHtml from 'sanitize-html';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { normalizePath } from "../../utils/get-url-path";
import sanitizeOptions from "../../helper/sanitizeOptions";
import Img from "gatsby-image";
import * as constants from "../../helper/constants";
import useWindowSize from "../../hooks/useWindowSize";

const SetClassItems = (i, windowSize) => {
    const blogItemPrimaryClass = 'third-width min-height';
    const blogItemSecondaryClass = 'third-width min-height';
    return (((i < 1) && !windowSize) ? blogItemPrimaryClass : blogItemSecondaryClass)
};

export const ItemPost = ({
                             i,
                             uri,
                             title,
                             date,
                             author,
                             postFields,
                             featuredImage,
                             tags
                         }) => {
    const sanitizeOpt = sanitizeOptions();
    const [windowSize, setWindowSize] = useState(false);
    useEffect(() => {
        setWindowSize(window.innerWidth < constants.MOBILE_WIDTH);
    }, []);

    const windowResizeSize = useWindowSize();
    const isMobile = windowResizeSize.width <= constants.MOBILE_WIDTH;

    return (
        <>
            <li className={`flex-item blog-item ${SetClassItems(i, windowSize)}`}>
                <AniLink
                    className={'blog-item-link'}
                    paintDrip
                    duration={1}
                    hex="#ed5d2b"
                    to={normalizePath(uri)}>
                    <div className={'post-image full-width'}>
                        {!!featuredImage?.node?.remoteFile?.childImageSharp && (
                            <Img
                                fluid={
                                    featuredImage.node.remoteFile.childImageSharp.fluid
                                }
                            />
                        )}
                    </div>
                    <div className={'description-wrapper'}>
                        <div className="post-info paragraph-secondary gray">
                            <span className="post-time">{date}</span>
                            <span className="post-author">{author.node.name}</span>
                        </div>
                        <h4 className={'post-title h4-title black'}>
                            {title}
                        </h4>
                    </div>
                    {!!postFields?.homeText && (
                        <div className={'post-content paragraph-primary black'}>
                             <span
                             dangerouslySetInnerHTML={{ __html: sanitizeHtml(postFields.homeText, sanitizeOpt.title) }}/>
                        </div>
                    )}
                </AniLink>
                {!!tags?.nodes && (
                    <div className={'tags-content'}>
                        {tags?.nodes.map((tag, i) => (
                                <AniLink to={normalizePath(tag.link)}
                                         paintDrip
                                         duration={1}
                                         className={'link-font-styles'}>
                                    <span>{tag.name}</span>
                                </AniLink>
                            )
                        )}
                    </div>
                )}

                <div className={'read-article-link'}>
                    <AniLink
                        paintDrip
                        duration={1}
                        hex="#ed5d2b"
                        className={'link-font-styles'}
                        to={normalizePath(uri)}
                    >
                        <span>Read article</span>
                    </AniLink>
                </div>
            </li>
        </>
    )
}