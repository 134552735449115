import React, { useState } from 'react';
import AccordionItem from "./accordion-item";
import TrackVisibility from 'react-on-screen';

const ServiceAccordion = ({
    items,
    title,
    titlePath = 'title',
    descPath = 'content',
}) => {

    const getValue = (item, path) => {
        if (!!item && !!path) {
            if (typeof path === 'string' && path.includes('.')) {
                path = path.split('.');
            }

            if (Array.isArray(path)) {
                const part = path.shift();
                if (path.length > 0) {
                    return getValue(item[part], path);
                }
                path = part;
            }

            if (item.hasOwnProperty(path)) {
                return item[path];
            }
        }
        return undefined;
    };

    const [activeAccordion, setActiveAccordion] = useState(null);

    return (
        <>
            {!!title && (
                <h2 className={'h3-title-services'}>{title}</h2>
            )}
            <div className={'accordion'}>
                {items.map((item, index) => (
                    <TrackVisibility 
                        once
                        offset={100}
                        key={index}
                    >
                        <AccordionItem key={index}
                                    index={index}
                                    activeIndex={activeAccordion}
                                    setActiveIndex={setActiveAccordion}
                                    title={getValue(item, titlePath)}
                                    description={getValue(item, descPath)}
                        />
                    </TrackVisibility>
                ))}
                
            </div>
        </>
    );
};

export default ServiceAccordion;