import React from "react"
import { graphql } from "gatsby"
import BlogPost from "../../components/template-parts/blog-post"

export default ({ data }) => <BlogPost data={data} />

export const query = graphql`
    query post($id: String!, $nextPage: String, $previousPage: String) {
        page: wpPost(id: { eq: $id }) {
            title
            uri
            content
            date(formatString: "DD MMM YYYY")
            author {
                    node {
                    name
                    description
                    avatar {
                        url
                    }
                 }
            }
            tags {
                nodes {
                    link
                    name
                }
            }
            postFields {
                homeText
                facebookImg {
                    mediaItemUrl
                }
            }
            featuredImage {
                node {
                    sourceUrl
                    remoteFile {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            postPageCustomFields {
              fieldGroupName
              sidebarRight
              contentBlocks {
                fieldGroupName
                title
                content
              }
              author {
                name
                description
                avatar {
                  url
                }
              }
            }
            servisesQuestionsLeft {
                title
                anyQuestionsLeft {
                    fieldGroupName
                    title
                    content {
                        contentWsw
                        fieldGroupName
                        internalList {
                            description
                            fieldGroupName
                        }
                    }
                }
            }
            seo {
                    title
                    metaDesc
                    canonical
                }
            additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage{
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
            }
        }
        
        recentNews: allWpPost(
             limit: 3
             filter: { id: { ne: $id } }
             sort: {fields: date, order: DESC}
            ) {
                nodes {
                    tags {
                        nodes {
                            link
                            name
                        }
                    }
                    id
                    uri
                    title
                    date(formatString: "DD MMM YYYY")
                    excerpt  
                    author {
                        node {
                            name
                        }
                    }
                    postFields {
                        homeText
                    }
                   featuredImage {
                        node {
                            sourceUrl
                            remoteFile {
                                ...Thumbnail
                            }
                        }
                    }
                }
            }
    
        mainPage: wpPage(slug: {eq: "blog"}){
            postMainCustomFields {
                postBreadcrumbs {
                    crumblabel
                    pathname
                }
            }
        }

        nextPage: wpPost(id: { eq: $nextPage }) {
          title
          uri
        }
    
        previousPage: wpPost(id: { eq: $previousPage }) {
          title
          uri
        }
    }
`
