import React, {useEffect, useState} from "react";
import sanitizeHtml from "sanitize-html";
import Img from "gatsby-image";
import Layout from "../../components/layout";
import Subscribe from "../subscribe";
import {globalHistory as history} from '@reach/router';
import sanitizeOptions from "../../helper/sanitizeOptions";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";
import ServiceAccordion from "../service-accordion";
import * as constants from "../../helper/constants";
import useWindowSize from "../../hooks/useWindowSize";
import {ItemPost} from "../posts-list/ItemPost";
import scrollTo from "gatsby-plugin-smoothscroll";

function BlogPost({data}) {
    const sanitizeOpt = sanitizeOptions();
    const {page, mainPage, recentNews} = data;
    const {
        title,
        tags,
        content,
        featuredImage,
        date,
        author,
        postFields,
        seo,
        additionalMetaTag,
        servisesQuestionsLeft,
        postPageCustomFields
    } = page;
    // Set breadcrumbs
    const {location} = history;
    const getCrumbs = Array.isArray(mainPage?.postMainCustomFields?.postBreadcrumbs) && mainPage.postMainCustomFields.postBreadcrumbs;
    const LastCrumb = {
        crumblabel: title,
        pathname: location.pathname
    };
    const crumbs = [...getCrumbs, LastCrumb];
    // Set metadata
    const postImages = postFields?.facebookImg?.mediaItemUrl;
    const seoData = {...{seo, postImages}, additionalMetaTag, title};
    const windowResizeSize = useWindowSize();
    const isMobile = windowResizeSize.width <= constants.DESKTOP_WIDTH;
    const [isHeightSticky, setHeightSticky] = useState('0');

    useEffect(() => {
        const heightSticky = document.getElementsByTagName('header')[0]?.offsetHeight + 24;

        const handleScrollEvent = () => {
            setHeightSticky(heightSticky);
        };
        document.addEventListener('scroll', handleScrollEvent);
    }, []);

    return (
        <Layout
            setClass={'post-page-wrapper'}
            seoData={seoData}>
            <div className={'blog-page blog-post-page'}>
                <div className="page-main page-content">
                    {!isMobile && postPageCustomFields?.contentBlocks &&
                        <div className={`sidebar-left`} style={{top: isHeightSticky}}>
                            <h3 className={'sidebar-title'}>On this page</h3>
                            {postPageCustomFields?.contentBlocks?.map((contentBlock, index) => {
                                return (
                                    <h2 key={`article-title_${index}`}>
                                        <a onClick={() => scrollTo(`#${'title_'+ index}`)}>{contentBlock?.title}</a>
                                    </h2>
                                )
                            })}
                        </div>
                    }
                    <div className={'column main post-page-width'} id={'column-main'}>
                        <div className={'page-title-container title-content title-item'}>
                            {!!tags?.nodes && (
                                <div className={'tags-content'}>
                                    {tags?.nodes.map((tag, i) => (
                                            <AniLink to={normalizePath(tag.link)}
                                                     paintDrip
                                                     duration={1}
                                                     className={'link-font-styles'}
                                                     key={`tag_${i}`}
                                            >
                                                <span>{tag.name}</span>
                                            </AniLink>
                                        )
                                    )}
                                </div>
                            )}

                            <div className={'page-title flex-item'}>
                                <h1 className={'h2-title black'}>
                                <span
                                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(title, sanitizeOpt.title) }}/>
                                </h1>
                            </div>
                            <div className={'post-info paragraph-secondary black'}>
                                <div className={'comment-image'}>
                                    {!!postPageCustomFields?.author?.avatar?.url && (
                                        <img src={postPageCustomFields?.author?.avatar.url}
                                             alt={postPageCustomFields?.author?.name}/>
                                    )}
                                </div>
                                <div className={'post-author-info'}>
                                    <div>
                                    <span className={'post-author'}>
                                        {postPageCustomFields?.author?.name}
                                    </span>
                                        <div className={'description position_name'}>
                                            {!!postPageCustomFields?.author?.description && (
                                                postPageCustomFields?.author?.description
                                            )}
                                        </div>
                                    </div>
                                    <span className={'post-time'}>
                                    {date}
                                </span>
                                </div>
                            </div>
                        </div>

                        <div className={"post-content"}>
                            {isMobile &&
                                <div className={'sidebar-left'}>
                                    <h3 className={'sidebar-title'}>On this page</h3>
                                    {postPageCustomFields?.contentBlocks?.map((contentBlock, index) => {
                                        return (
                                            <h2 key={`title_${index}`}><a href={'#title_'+ index}>{contentBlock.title}</a></h2>
                                        )
                                    })}
                                </div>
                            }
                            {!!postFields?.homeText && (
                                <div className={'title-content title-item post-page-width'}>
                                    <div className={'post-preview'}>
                                        <h5 className={'h5-title'}>
                                            <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(postFields.homeText, sanitizeOpt.title) }}/>
                                        </h5>
                                    </div>
                                </div>
                            )}
                            <section dangerouslySetInnerHTML={{ __html: sanitizeHtml(content, sanitizeOpt.contentData) }}/>
                            {isMobile && postPageCustomFields?.sidebarRight &&
                                <div className={'sidebar-right'}>
                                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(postPageCustomFields?.sidebarRight, sanitizeOpt.contentData)}}/>
                                </div>
                            }

                            {postPageCustomFields?.contentBlocks?.map((contentBlock, index) => {
                                return (
                                    <div className={'content-bocks'} key={`content-bocks_${index}`}>
                                        <h2 id={'title_'+ index}
                                            className={'article-title'}
                                            style={{scrollMarginTop: isHeightSticky + 30}}>{contentBlock.title}</h2>
                                        <div dangerouslySetInnerHTML={{__html: sanitizeHtml(contentBlock.content, sanitizeOpt.contentData)}}/>
                                    </div>
                                )
                            })}
                        </div>

                        {!!featuredImage?.node?.remoteFile?.childImageSharp && (
                            <div className="post-content-container">
                                <div className="main-image">
                                    <Img fluid={featuredImage.node.remoteFile.childImageSharp.fluid}/>
                                </div>
                            </div>
                        )}
                    </div>
                    {!isMobile && postPageCustomFields?.sidebarRight &&
                        <div className={'sidebar-right'} style={{top: isHeightSticky}}>
                            <div dangerouslySetInnerHTML={{__html: sanitizeHtml(postPageCustomFields?.sidebarRight, sanitizeOpt.contentData)}}/>
                        </div>
                    }
                </div>

                <Subscribe typePage={'post-page-width'} formWrap={'flex-container-nowrap'}/>

                <div className={'grid blog-wrapper'}>
                    <div className={'top-content'}>
                        <div className={'left-container flex-container'}>
                            <h2 className={'black h3-title-services'}>
                                {'Related Posts'}
                            </h2>
                        </div>
                        <div className={'right-container'}
                             data-animated-group="animated-content">
                            <AniLink to={normalizePath('blog')}
                                     paintDrip
                                     duration={1}
                                     hex="#ed5d2b"
                                     className={'transition-link paragraph-primary__bold mc-link main-link mc-button-secondary right link-font-styles'}>
                                {'Discover our blog'}
                            </AniLink>
                        </div>
                    </div>
                    <ul className={'blog-list grid full-width'}>
                        {recentNews.nodes.map((post, i) => (
                                <ItemPost
                                    i={i}
                                    key={i + post?.uri}
                                    uri={post?.uri}
                                    title={post?.title}
                                    date={post?.date}
                                    author={post?.author}
                                    postFields={post?.postFields}
                                    featuredImage={post?.featuredImage}
                                    tags={post?.tags}
                                />
                            )
                        )}
                    </ul>
                </div>

                {!!servisesQuestionsLeft?.anyQuestionsLeft && (
                    <div className={'service-accordion page-content'}>
                        <ServiceAccordion
                            title={servisesQuestionsLeft.title}
                            titlePath={servisesQuestionsLeft.anyQuestionsLeft.title}
                            descPath={servisesQuestionsLeft.anyQuestionsLeft.content}
                            items={servisesQuestionsLeft.anyQuestionsLeft}
                        />
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default BlogPost
